import React from 'react';
import { flowRight, get } from 'lodash';
import PropTypes from 'prop-types';

import {
  ensureRicosContent,
  Version,
  isHexColor,
  pluginCodeBlockViewer as pluginCodeBlock,
  pluginHashtagViewer as pluginHashtag,
  pluginIndentViewer as pluginIndent,
  pluginLineSpacingViewer as pluginLineSpacing,
  pluginLinkViewer as pluginLink,
  pluginTextColorViewer as pluginTextColor,
  pluginTextHighlightViewer as pluginTextHighlight,
  TextSelectionToolbar,
  TwitterButton,
  WixRicosViewer,
} from '@wix/ricos';

import {
  pluginAudioLoadable as pluginAudio,
  pluginLinkButtonLoadable as pluginLinkButton,
  pluginActionButtonLoadable as pluginActionButton,
  pluginCollapsibleListLoadable as pluginCollapsibleList,
  pluginDividerLoadable as pluginDivider,
  pluginFileUploadLoadable as pluginFileUpload,
  pluginGalleryLoadable as pluginGallery,
  pluginGiphyLoadable as pluginGiphy,
  pluginHtmlLoadable as pluginHtml,
  pluginImageLoadable as pluginImage,
  pluginLinkPreviewLoadable as pluginLinkPreview,
  pluginPollLoadable as pluginPoll,
  pluginTableLoadable as pluginTable,
  pluginVerticalEmbedLoadable as pluginVerticalEmbed,
  pluginVideoLoadable as pluginVideo,
} from '@wix/ricos/loadable';

import '@wix/ricos/css/plugin-hashtag-viewer.global.css';
import '@wix/ricos/css/plugin-link-viewer.global.css';
import '@wix/ricos/css/ricos-viewer.global.css';
import {
  isExperimentEnabled,
  getIsPostPageBundle,
  getExperiments,
  getCurrentUser,
  resolveId,
} from '@wix/communities-blog-client-common';
import {
  EXPERIMENT_HASHTAG_PAGE_USE_FEED_PAGE,
  EXPERIMENT_RICOS_EMBED_BUTTONS,
} from '@wix/communities-blog-experiments';
import { RicosViewerWrapper } from '@wix/communities-blog-client-common/dist/esm/components';
import { OOI_EXPERIMENTS } from '../../../../experiments';
import { connect } from '../../../common/components/runtime-context';
import withDeviceType from '../../../common/hoc/with-device-type';
import withMediaHosts from '../../../common/hoc/with-media-hosts';
import { getAppSettings } from '../../../common/selectors/app-settings-base-selectors';
import { getTwitterEnabled } from '../../../common/selectors/app-settings-selectors';
import { shouldApplyPostDesignInFeed } from '../../../common/selectors/full-post-selectors';
import { getLocale } from '../../../common/selectors/locale-selectors';
import { getIframeSandboxDomain } from '../../../common/selectors/rce-props-selectors';
import { getSection } from '../../../common/selectors/section-selectors';
import Wix from '../../../common/services/wix-sdk-polyfill';
import {
  isSeo as getIsSeo,
  getInstance,
  getUrl,
  isEditor,
  isPreview,
} from '../../../common/store/basic-params/basic-params-selectors';
import {
  getInstanceValue,
  getMetaSiteId,
} from '../../../common/store/instance-values/instance-values-selectors';
import {
  getBaseUrl,
  getSectionUrl,
} from '../../../common/store/topology/topology-selectors';

const RicosViewer = ({
  initialRawState: content,
  requestFileDownloadUrl,
  requestLogin,
  showMessage,
  fileDownloaded,
  isDemoPost,
  ...rest
}) => {
  const props = {
    disableTheme: true,
    ...rest,
    WixRicosViewer,
    pluginAudio,
    pluginCodeBlock,
    pluginDivider,
    pluginLinkButton,
    pluginGallery,
    pluginGiphy,
    pluginHashtag,
    pluginHtml,
    pluginImage,
    pluginLineSpacing,
    pluginIndent,
    pluginLink,
    pluginLinkPreview,
    pluginTable,
    pluginTextColor,
    pluginTextHighlight,
    pluginVideo,
    pluginFileUpload,
    pluginActionButton,
    pluginVerticalEmbed,
    pluginCollapsibleList,
    pluginPoll,
    content: ensureRicosContent(content, { ignoreUnsupportedValues: true }),
    rceVersion: Version.currentVersion,
    isHexColor,
    TextSelectionToolbar,
    TwitterButton,
    Wix,
    isDemoPost,
    actions: {
      requestFileDownloadUrl,
      requestLogin,
      showMessage,
    },
    bi: {
      fileDownloaded,
    },
  };

  return <RicosViewerWrapper {...props} />;
};

RicosViewer.propTypes = {
  appSettings: PropTypes.object.isRequired,
  isSeo: PropTypes.bool.isRequired,
  isInEditor: PropTypes.func.isRequired,
  isInPreview: PropTypes.func.isRequired,
  isPostPageBundle: PropTypes.func.isRequired,
  hashtagNavigate: PropTypes.func.isRequired,
  pageUrl: PropTypes.string.isRequired,
  videoHost: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  instance: PropTypes.string.isRequired,
  metasiteId: PropTypes.string.isRequired,
  sectionUrl: PropTypes.string.isRequired,
  isHashtagPageAsFeedPageEnabled: PropTypes.bool.isRequired,
  isRicosEmbedButtonsEnabled: PropTypes.bool.isRequired,
  isTwitterEnabled: PropTypes.bool.isRequired,
  isAvifEncodingEnabled: PropTypes.bool.isRequired,
  parentClass: PropTypes.string,
  experiments: PropTypes.object.isRequired,
  isDemoPost: PropTypes.bool,
  shouldApplyPostDesignInFeed: PropTypes.string.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  appSettings: getAppSettings(state),
  isSeo: getIsSeo(state),
  isInEditor: isEditor(state),
  isInPreview: isPreview(state),
  isPostPageBundle: getIsPostPageBundle(state),
  locale: getLocale(state),
  postId: resolveId(ownProps.post),
  instance: getInstance(state),
  pageUrl: getUrl(state),
  hashtagNavigate: actions.navigateWithinBlog,
  baseUrl: getBaseUrl(state),
  section: getSection(state),
  sectionUrl: getSectionUrl(state),
  iframeSandboxDomain: getIframeSandboxDomain(state),
  requestFileDownloadUrl: actions.requestFileDownloadUrlPromisified,
  requestLogin: actions.requestLoginPromisified,
  currentUser: getCurrentUser(state),
  showMessage: actions.showMessage,
  fileDownloaded: actions.fileDownloaded,
  isHashtagPageAsFeedPageEnabled: isExperimentEnabled(
    state,
    EXPERIMENT_HASHTAG_PAGE_USE_FEED_PAGE,
  ),
  isRicosEmbedButtonsEnabled: isExperimentEnabled(
    state,
    EXPERIMENT_RICOS_EMBED_BUTTONS,
  ),
  isAvifEncodingEnabled: isExperimentEnabled(
    state,
    OOI_EXPERIMENTS.USE_AVIF_ENCODING,
  ),
  isTwitterEnabled: getTwitterEnabled(state),
  experiments: getExperiments(state),
  isDemoPost: get(ownProps, 'post.isDemo', false),
  shouldApplyPostDesignInFeed: shouldApplyPostDesignInFeed(state),
  metasiteId: getMetaSiteId(state),
  visitorId: getInstanceValue(state, 'aid') || getInstanceValue(state, 'uid'),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withDeviceType,
  withMediaHosts,
)(RicosViewer);
